import Image from 'next/image';
import { useState } from 'react';

import { staticMediaStoreBaseURL } from '@/config/common';
import { useIsMobile } from '@/lib/screenResolution';

const handleOnChange = ({
  setIsSearchDropdownOpen,
  setSearchableAttribute,
  value
}) => {
  setSearchableAttribute(value);
  setIsSearchDropdownOpen(false);
};

const SearchDropDownOptions = ({
  searchableAttribute,
  searchAttributeLabelList,
  setIsSearchDropdownOpen,
  setSearchableAttribute
}) => (
  <div className='w-44 border shadow-sm border-platinum bg-white absolute z-2 !left-0 mt-11 md:mt-14 p-4 search-filter-modal'>
    <ul className='flex flex-col gap-4'>
      {searchAttributeLabelList.map(({ esDocKey, key, label }, id) => (
        <li
          key={`${key}_${id}`}
          className='relative block searchkit-filter-menu-item cursor-pointer'
        >
          <label className='cursor-pointer'>
            <input
              {...{
                checked: searchableAttribute === esDocKey,
                className:
                  'checked:content-searchkit-filter-menu-item-checked searchkit-filter-menu-item-checked searchkit-radio',
                name: 'searchable attribute option',
                onChange: () =>
                  handleOnChange({
                    setIsSearchDropdownOpen,
                    setSearchableAttribute,
                    value: esDocKey
                  }),
                type: 'radio',
                value: key
              }}
            />
            <span className='before:bg-searchkit-filter-menu-item empty-radio-span inline-block w-5 h-5'></span>
            <span className='text-sm text-dim-gray font-medium pl-2 self-center'>
              {label}
            </span>
          </label>
        </li>
      ))}
    </ul>
  </div>
);

const ScopeSearchAttributeModal = ({
  searchableAttribute,
  searchAttributeLabelList,
  setSearchableAttribute
}) => {
  const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(false);
  const searchableAttributeLabel = searchAttributeLabelList
    .filter(({ esDocKey }) => esDocKey === searchableAttribute)
    .map(({ label }) => label);

  const [isMobile] = useIsMobile();
  return (
    <div className='search-filter-icon flex cursor-pointer absolute top-[5px] left-[6px]'>
      <div
        onClick={() => setIsSearchDropdownOpen(!isSearchDropdownOpen)}
        className='relative cursor-pointer flex justify-center items-center border border-brand rounded-full py-2 w-48 px-4'
      >
        <span className='text-base font-medium text-brand'>
          {searchableAttributeLabel}
        </span>
        <Image
          alt='select-search-attribute'
          className='absolute right-4'
          height={isMobile ? 11 : 12}
          src={`${staticMediaStoreBaseURL}/icons/down-angle-brand-icon.svg`}
          width={isMobile ? 11 : 12}
        />
      </div>
      {isSearchDropdownOpen && (
        <SearchDropDownOptions
          {...{
            searchableAttribute,
            searchAttributeLabelList,
            setIsSearchDropdownOpen,
            setSearchableAttribute
          }}
        />
      )}
    </div>
  );
};

export default ScopeSearchAttributeModal;
